import React, { useEffect, useMemo, useState } from 'react';
import VacancyCard from '../components/vacancy-card/vacancy-card';
import { graphql } from 'gatsby';
import {
  Vacancy,
  VacancyContractModel,
  VacancyPaginated,
  VacancyRemunerationModel,
} from '../sdk/entities/Vacancy';
import { SEO } from '../components/seo/seo';
import { Address } from '../sdk/entities/Address';
import { Layout } from '../components/layout/layout';
import VacanciesList from '../components/vacancies-list/vacancies-list';
import { getLogo, getWorkplaceName } from '../utils/vacancy';
import InfoBanner from '../components/info-banner/info-banner';
import { HiChevronLeft } from '@react-icons/all-files/hi/HiChevronLeft';
import axios from 'axios';
import { BASE_URL } from '../config';
import { Spinner } from '../components/spinner/spinner';

export type VacancyPageProps = {
  data: {
    vacancy: Vacancy;
    workFieldVacancies: {
      nodes: Vacancy[];
    };
    positionVacancies: {
      nodes: Vacancy[];
    };
  };
};

const VacancyPage: React.FC<VacancyPageProps> = ({ data }) => {
  const [companyVacancies, setCompanyVacancies] = useState<Vacancy[]>();
  let locationSearch = ``;
  if (typeof window !== `undefined`) {
    locationSearch = window.location.search;
  }
  const params = new URLSearchParams(locationSearch);
  const similarVacancies = useMemo(() => {
    const vacancies = data.workFieldVacancies.nodes.concat(
      data.positionVacancies.nodes,
    );

    return vacancies.slice(0, 5);
  }, [data]);
  const hideSimilarVacancies =
    params?.get('utm_medium') === 'bot_vendoor' ||
    params?.get('utm_medium') === 'company_page';

  const getCompanyVacancies = async () => {
    const newParams = new URLSearchParams();
    newParams.append('companyId', data.vacancy.companyId);
    newParams.append('limit', '5');

    const url = `${BASE_URL}/public/vacancies/active?${newParams.toString()}`;

    const response = await axios.get(url);
    const companyVacancies = response.data as VacancyPaginated;
    const vacancies = companyVacancies.items.filter(
      item => item.id !== data.vacancy.id,
    );
    setCompanyVacancies(vacancies);
  };

  useEffect(() => {
    if (!data.vacancy || !hideSimilarVacancies) return;
    getCompanyVacancies();
  }, [data.vacancy]);

  return (
    <main>
      <Layout
        breadcrumbItems={[
          {
            title: data.vacancy.position?.workField?.name!,
            href: `/${data.vacancy.position?.workField?.slug}/`,
          },
          {
            title: data.vacancy.position?.name!,
            href: `/${data.vacancy.position?.workField?.slug}/${data.vacancy.position?.slug}/`,
          },
          {
            title: data.vacancy.name,
            href: `/vagas/${data.vacancy.slug}/`,
          },
        ]}
        hideSearch
        hideFilter={true}
        removeZIndex
      >
        <div className="container mx-auto p-5">
          <div className="block lg:hidden mb-9">
            <InfoBanner mobile />
          </div>
          <div className="flex mb-9">
            <a
              href={`/${data.vacancy.position?.workField?.slug}/${data.vacancy.position?.slug}/`}
            >
              <HiChevronLeft size={30} />
            </a>
            <h2 className="text-black text-xl lg:text-2xl font-bold leading-[28.80px]">
              Detalhes da vaga
            </h2>
          </div>
          <div className="lg:flex gap-6">
            <div className="flex-1">
              {data?.vacancy && <VacancyCard vacancy={data.vacancy} />}
            </div>

            <div className="hidden lg:block">
              <InfoBanner />
            </div>
          </div>
        </div>

        {!!companyVacancies && !!companyVacancies.length && (
          <div className="my-16">
            <VacanciesList
              vacancies={companyVacancies}
              utmMedium={params.get('utm_medium') || ''}
              title={'Vagas da empresa'}
              queryParams={{
                utmSource: params.get('utm_source') || '',
                companyId: data.vacancy.companyId,
              }}
              moreVacanciesLabel="Ver mais vagas da empresa"
            />
          </div>
        )}

        {!hideSimilarVacancies && (
          <div className="my-16">
            <VacanciesList
              title={'Vagas Similares'}
              vacancies={similarVacancies}
              queryParams={{
                positionId: data.vacancy.position?.id,
                workFieldId: data.vacancy.position?.workField?.id,
                utmSource: params.get('utm_source') || '',
              }}
              moreVacanciesLabel={'Ver mais vagas similares'}
            />
          </div>
        )}
      </Layout>
    </main>
  );
};

export const Head: React.FC<VacancyPageProps> = ({ data }) => {
  const workplaceName = getWorkplaceName(data.vacancy);
  const logo = getLogo(data.vacancy);

  const getJobPosting = (vacancy: Vacancy) => {
    if (!vacancy.active) return;
    const address = vacancy.workplace?.address;
    const employmentTypeByContractModel = (model: VacancyContractModel) => {
      if (model === VacancyContractModel.PJ) return ['FULL_TIME', 'CONTRACTOR'];
      if (model === VacancyContractModel.CLT) return 'FULL_TIME';
      if (model === VacancyContractModel.FREELANCER) return 'CONTRACTOR';
      if (model === VacancyContractModel.INTERN) return 'INTERN';
    };

    const formatStreetAddress = (address: Address) => {
      const complement = address.complement ? `, ${address.complement}` : '';
      return `${address.street}, ${address.number}${complement}. ${address.district}.`;
    };

    return [
      {
        '@context': 'https://schema.org',
        '@type': 'JobPosting',
        datePosted: vacancy.createdAt,
        validThrough: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
          .toISOString()
          .split('T')[0],
        description: vacancy.description,
        title: vacancy.position?.name,
        name: vacancy.name,
        directApply: true,
        employmentType: employmentTypeByContractModel(vacancy.contractModel),
        identifier: {
          '@type': 'PropertyValue',
          name: 'Vendoor',
          value: `vendoorvagas.com.br`,
        },
        hiringOrganization: {
          '@type': 'Organization',
          name: workplaceName,
          logo: logo,
        },
        jobLocation: [
          {
            '@type': 'Place',
            address: {
              '@type': 'PostalAddress',
              addressCountry: address?.country,
              addressLocality: address?.city,
              addressRegion: address?.state,
              postalCode: address?.postalCode,
              streetAddress: address && formatStreetAddress(address),
            },
          },
        ],
        baseSalary: {
          '@type': 'MonetaryAmount',
          currency: 'BRL',
          value: {
            '@type': 'QuantitativeValue',
            unitText: 'MONTH',
            ...(vacancy.remunerationModel === VacancyRemunerationModel.FIXED
              ? {
                  value: vacancy.remuneration,
                }
              : {
                  minValue: vacancy.remuneration || 0,
                  maxValue: vacancy.maxRemuneration,
                }),
          },
        },
      },
      vacancy.confidential
        ? undefined
        : {
            '@context': 'https://schema.org',
            '@type': 'LocalBusiness',
            name: vacancy.workplace?.name,
            image: vacancy.workplace?.image,
            description: vacancy.workplace?.description,
            address: {
              '@type': 'PostalAddress',
              addressCountry: address?.country,
              addressLocality: address?.city,
              addressRegion: address?.state,
              postalCode: address?.postalCode,
              streetAddress: address && formatStreetAddress(address),
            },
          },
    ];
  };

  return (
    <SEO
      title={data.vacancy.name}
      description={data.vacancy.description}
      image={logo}
      pathname={`/vagas/${data.vacancy.slug}/`}
      schema={getJobPosting(data.vacancy)}
      breadcrumbItems={[
        {
          title: data.vacancy.position?.workField?.name!,
          href: `/${data.vacancy.position?.workField?.slug}/`,
        },
        {
          title: data.vacancy.position?.name!,
          href: `/${data.vacancy.position?.workField?.slug}/${data.vacancy.position?.slug}/`,
        },
        {
          title: data.vacancy.name,
          href: `/vagas/${data.vacancy.slug}/`,
        },
      ]}
    />
  );
};

export const query = graphql`
  query ($id: String, $positionSlug: String, $workFieldSlug: String) {
    vacancy(_id: { eq: $id }) {
      id
      name
      remuneration
      maxRemuneration
      remunerationModel
      description
      active
      companyId
      slug
      confidential
      desirableProfessionalExperience
      createdAt
      benefits
      contractModel
      vacancyRequirement
      image {
        childImageSharp {
          gatsbyImageData(
            width: 40
            height: 40
            transformOptions: { fit: COVER }
            quality: 80
          )
        }
      }
      position {
        name
        slug
        id
        workField {
          name
          slug
          id
        }
      }
      competences {
        id
        competence {
          name
        }
      }
      workplace {
        name
        description
        image
        address {
          street
          number
          district
          complement
          city
          state
          country
          postalCode
          referencePoint
          lat
          lng
        }
      }
      officeDays
      company {
        slug
      }
    }
    workFieldVacancies: allVacancy(
      sort: { createdAt: DESC }
      limit: 5
      filter: {
        active: { eq: true }
        position: {
          workField: { slug: { eq: $workFieldSlug } }
          slug: { ne: $positionSlug }
        }
      }
    ) {
      nodes {
        id
        _id
        name
        maxRemuneration
        confidential
        contractModel
        description
        remuneration
        remunerationModel
        slug
        position {
          slug
          workField {
            slug
          }
        }
        workplace {
          name
          address {
            city
            complement
            country
            district
            lat
            lng
            number
            postalCode
            referencePoint
            state
            street
          }
          image
        }
        officeDays
        image {
          childImageSharp {
            gatsbyImageData(
              width: 40
              height: 40
              transformOptions: { fit: COVER }
              quality: 80
            )
          }
        }
        companyId
      }
    }
    positionVacancies: allVacancy(
      sort: { createdAt: DESC }
      limit: 5
      filter: {
        active: { eq: true }
        position: { slug: { eq: $positionSlug } }
      }
    ) {
      nodes {
        id
        _id
        name
        maxRemuneration
        confidential
        contractModel
        description
        remuneration
        remunerationModel
        slug
        position {
          slug
          workField {
            slug
          }
        }
        workplace {
          name
          address {
            city
            complement
            country
            district
            lat
            lng
            number
            postalCode
            referencePoint
            state
            street
          }
          image
        }
        officeDays
        image {
          childImageSharp {
            gatsbyImageData(
              width: 40
              height: 40
              transformOptions: { fit: COVER }
              quality: 80
            )
          }
        }
        companyId
      }
    }
  }
`;

export default VacancyPage;

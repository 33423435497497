import { AiFillLike } from '@react-icons/all-files/ai/AiFillLike';
import { FiMap } from '@react-icons/all-files/fi/FiMap';
import { IoLockClosedSharp } from '@react-icons/all-files/io5/IoLockClosedSharp';
import { MdLocationOn } from '@react-icons/all-files/md/MdLocationOn';
import { copy } from 'clipboard';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { FC, useMemo, useState } from 'react';
import {
  Vacancy,
  VacancyDesirableProfessionalExperienceEnum,
  VacancyScale,
} from '../../sdk/entities/Vacancy';
import { formatVacancyAddress } from '../../utils/address';
import { formatOfficeDayDisplay, formatOfficeDays } from '../../utils/date';
import { formatContractModel, getWorkplaceName } from '../../utils/vacancy';
import ModalCandidateLink from '../modal-candidate-link/modal-candidate-link';
import VacancySalary from '../vacancy-salary/vacancy-salary';
import * as classes from './vacancy-card.module.scss';

export interface VacancyCardProps {
  vacancy: Vacancy;
}

const VacancyCard: FC<VacancyCardProps> = ({ vacancy }) => {
  const workplaceName = getWorkplaceName(vacancy);
  const image = vacancy.image ? getImage(vacancy.image) : undefined;
  const officeDays = formatOfficeDays(JSON.parse(vacancy.officeDays));
  const [shared, setShared] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const benefits = useMemo(() => {
    return JSON.parse(vacancy.benefits);
  }, [vacancy.benefits]);

  const handleCopyLink = async () => {
    let url = `https://vendoorvagas.com.br${window.location.pathname}`;

    const confirmShareLink = (url: string) => {
      if (!navigator.share) {
        copy(`Veja essa vaga no Vendoor\n\n\n${url}`);
        return;
      }

      navigator
        .share({
          title: 'Veja essa vaga no Vendoor',
          url,
        })
        .catch(console.error);
    };

    confirmShareLink(url);

    setShared(true);

    setTimeout(() => {
      setShared(false);
    }, 5000);
  };

  const candidateLink = (shareButton?: boolean) => {
    if (!vacancy.active) {
      return (
        <a
          href={`https://vendoorvagas.com.br/${vacancy?.position?.workField?.slug}/${vacancy?.position?.slug}/`}
          className={classes.inactive}
        >
          Vaga desativada, clique aqui para ver outras vagas
        </a>
      );
    }

    return (
      <div>
        <button
          onClick={() => setShowModal(true)}
          className={classes.candidateButton}
        >
          ME CANDIDATAR <AiFillLike />
        </button>
        {shareButton && (
          <button
            onClick={handleCopyLink}
            className={`${classes.button} ${shared ? classes.shared : ''}`}
          >
            {shared ? 'Link copiado!' : 'Compartilhar'}
          </button>
        )}
      </div>
    );
  };

  return (
    <div className={classes.container}>
      {vacancy.confidential && (
        <div className={classes.confidential}>
          Empresa Confidencial
          <IoLockClosedSharp size="14px" color="#FFFFFF" />
        </div>
      )}

      <div className={classes.content}>
        <div className={classes.topCard}>
          <div>
            <h1 className={classes.vacancy}>{vacancy.name}</h1>
            <div className={classes.workplace}>
              {image && (
                <GatsbyImage
                  image={image}
                  alt={`Logo da vaga ${vacancy.name}`}
                  className={classes.img}
                />
              )}
              <h2>{workplaceName}</h2>
            </div>
          </div>

          <div className={`${vacancy.active ? 'hidden md:block' : 'block'}`}>
            {candidateLink()}
          </div>
        </div>

        <div className={classes.separator} />

        <div className={`${classes.card} ${classes.addressCard}`}>
          <div>
            <h2 className={classes.title}>Endereço</h2>
            <div className={classes.addressLine}>
              <MdLocationOn size="17px" color="#718096" />
              {vacancy.workplace?.address && (
                <p className={classes.value}>{formatVacancyAddress(vacancy)}</p>
              )}
            </div>
          </div>
          {!vacancy.confidential && (
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${vacancy?.workplace?.address?.lat},${vacancy?.workplace?.address?.lng}`}
              target="_blank"
              className={classes.mapButton}
              aria-label="Abrir mapa"
            >
              <FiMap color="#FFFFFF" size="16px" />
            </a>
          )}
        </div>

        <div className={classes.separator} />

        <div className={classes.card}>
          <h3 className={classes.title}>Salário</h3>
          <VacancySalary vacancy={vacancy} />
        </div>

        {benefits?.length > 0 && (
          <div className={classes.card}>
            <h3 className={classes.title}>Benefícios</h3>
            <ul>
              {benefits?.map((benefit: { name: string }) => (
                <li key={benefit.name}>{benefit.name}</li>
              ))}
            </ul>
          </div>
        )}

        <div className={classes.card}>
          <h3 className={classes.title}>Forma de contratação</h3>
          <p className={classes.value}>
            {formatContractModel(vacancy.contractModel)}
          </p>
        </div>

        <div className={classes.separator} />

        <div className={classes.card}>
          <h2 className={classes.title}>Jornada de trabalho</h2>

          <div className={classes.workingDays}>
            {officeDays?.map((officeDay, index, array) => (
              <div key={officeDay.firstDay}>
                {index > array.length - 1 && (
                  <p className={classes.other}>Ou</p>
                )}
                {officeDay.scale === VacancyScale.SCALE_12x36 && (
                  <p className={classes.scale}>
                    {officeDay.firstDay} a {officeDay.lastDay}
                    <span className={classes.scale}>: Escala 12x36</span>
                  </p>
                )}
                <p
                  className={classes.value}
                  dangerouslySetInnerHTML={{
                    __html: formatOfficeDayDisplay(officeDay),
                  }}
                />

                {officeDay.full &&
                  officeDay.scale !== VacancyScale.SCALE_12x36 && (
                    <p className={classes.value}>
                      Folga semanal combinada com gestor
                    </p>
                  )}
              </div>
            ))}
          </div>
        </div>

        <div className={classes.separator} />

        <div className={classes.card}>
          <h2 className={classes.title}>Requisitos da vaga</h2>
          <div className={classes.value}>
            {vacancy?.desirableProfessionalExperience?.includes(
              VacancyDesirableProfessionalExperienceEnum.DESIRABLE_WITHOUT_EXPERIENCE,
            ) && (
              <p className={classes.normalValue}>
                Vaga para pessoas inexperientes
              </p>
            )}
            <p
              dangerouslySetInnerHTML={{ __html: vacancy.vacancyRequirement }}
            />
          </div>

          {vacancy.competences && vacancy.competences?.length > 0 && (
            <div className={classes.competencesWrapper}>
              <h2 className={classes.value}>Competências desejadas:</h2>
              <ul>
                {vacancy.competences.map(competence => (
                  <li key={competence.id}>{competence.competence.name}</li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div className={classes.separator} />

        <div className={classes.card}>
          <h2 className={classes.title}>Detalhes da vaga</h2>

          <p
            className={classes.value}
            dangerouslySetInnerHTML={{ __html: vacancy.description }}
          />
        </div>

        <div className={classes.separator} />

        {vacancy.workplace?.description && (
          <>
            <div className={classes.card}>
              <h3 className={classes.title}>Detalhes do anunciante</h3>
              <p className={classes.value}>{vacancy.workplace?.description}</p>
            </div>

            <div className={classes.separator} />
          </>
        )}

        <div className={classes.card}>{candidateLink(true)}</div>
      </div>
      {vacancy.active && (
        <div className={classes.fixedLink}>{candidateLink()}</div>
      )}
      {showModal && (
        <ModalCandidateLink
          onClose={() => setShowModal(false)}
          vacancy={vacancy}
        />
      )}
    </div>
  );
};

export default VacancyCard;
